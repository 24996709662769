import { RewardCalculatorPortalFlow } from '/features/buildingBlocks/reward-calculator/RewardCalculatorPortalFlow'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { routeMap } from '/routeMap'

import styles from './ShowRewardCalculatorPortalFlowButton.css'

/** @param {ShowRewardCalculatorPortalFlowButtonProps} props */
export function ShowRewardCalculatorPortalFlowButton({ buttonType, dataX, label, linkRef, layoutClassName = undefined }) {
  const [isCalculatorOpen, setIsCalculatorOpen] = React.useState(false)
  const ButtonComponent = getButtonComponent({ buttonType })
  const language = useLanguage()
  const { __ } = useTranslate()

  const link = linkRef && {
    url: routeMap.app.page({ language, slug: linkRef.slug.current }),
    label: __`see-the-benefits`
  }

  return (
    <>
      <ButtonComponent {...{ dataX, label, layoutClassName }} onClick={() => setIsCalculatorOpen(true)} />
      <RewardCalculatorPortalFlow
        isOpen={isCalculatorOpen}
        onChange={setIsCalculatorOpen}
        {...{ link }}
      />
    </>
  )
}

function getButtonComponent({ buttonType }) {
  switch (buttonType) {
    case 'label' : return Label
    case 'tile' : return Tile
    default: throw new Error('Invalid button type passed in ShowRewardCalculatorPortalFlowButton component. Use one of the following: \'label\', \'tile\'')
  }
}

function Label({ label, onClick }) {
  return (
    <button className={styles.componentLabel} {...{ onClick }}>
      <span>{label}</span>
    </button>
  )
}

function Tile({ label, onClick, layoutClassName }) {
  return (
    <button className={cx(styles.componentTile, layoutClassName)} {...{ onClick }}>
      <span>{label}</span>
    </button>
  )
}

/**
 * @typedef {{
 *  label: string,
 *  buttonType: 'label' | 'tile',
 *  dataX: string,
 *  linkRef: Link,
 *  layoutClassName?: string
 * }} ShowRewardCalculatorPortalFlowButtonProps
 */

/** * @typedef {{ slug: { current?: string } }} Link */
